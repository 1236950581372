.ourClients {
  height: 70vh;
  /* display: flex; */
  /* background-color: rgb(205, 203, 203); */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.clientCards {
  /* box-shadow: 0 0 5px #8b0000; */
  /* border-color: #8b0000; */
  border-radius: 25px;
  /* background-color: #8b0000; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.imageWrapper {
  /* background-color: black; */
  padding: 10%;
}

.image {
  max-width: 300px;
  width: 300px;
  height: 72.84;
  max-height: 72.84;
}

.grid {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10%;
  padding-right: 10%;
}

@media screen and (min-width: 680px) {
  .grid {
    /* text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* padding-left: 10%;
    padding-right: 10%; */
  }
}
