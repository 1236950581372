@media screen and (max-width: 600px) {
  .projectImg {
    /* display: none; */
    width: 300px;
    height: 300px;
  }

  .projectImgGrid {
    display: flex;
    align-items: center;
    /* padding-top: 50px; */
  }
}
