.ssi-logo {
  width: 200px;
  height: 100px;
  /* margin-left: 20%; */
}

@media screen and (max-width: 600px) {
  .navigation-menu {
    display: none;
  }
}

/* .imgContainer {
  align-items: center;
  justify-content: center;
} */
.navigation {
  height: 5vh;
  width: 100%;
  display: flex;
  align-items: center;
  position: sticky;
  padding: 0.5rem 0rem;
  background-color: #fff;
  color: black;
  border-width: 1px;
  background: transparent;
  /* border: 1px solid red; */
  /* padding-left: 50px;
  padding-right: 50px; */
  /* box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23); */
}
/* background: linear-gradient(
    #ffc0cb 50%,
    #00ffff 50%
  ); */

.navContainer {
  display: flex;
  padding: 0;
}
.navContainer p {
  list-style-type: none;
  margin: 0 1rem;
}

.navigation-menu {
  margin-left: auto;
  /* margin-right: 10%; */
}
