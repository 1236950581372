.App {
  /* background: linear-gradient(
    132deg,
    rgba(68, 68, 255, 0.09429709383753504) 31%,
    rgba(154, 0, 0, 0.7693671218487395) 100%
  ); */
  /* display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column; */
}

.App1 {
  flex-direction: row;
}

.sectionHeaders {
  font-size: 50px;
  text-align: center;
}
/* .blackCL {
  padding-left: 40%;
  transform: translateX(30%) translateY(-20%);
} */
/*

.App-logo {
  height: 40vmin;
  pointer-events: none;
  animation: grow 2s 1;
}

@keyframes grow {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
} */

/* .Circle3 {
  animation: grow 2s 1;
}

@keyframes grow {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
} */
