.hero-component {
  height: 95vh;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */

  /* background-color: #dbe6fc; */
  /* background-color: bisque; */
}

.headerText {
  font-size: 50px;
  animation: textUp 1s ease-in;
}

p {
  text-align: center;
  margin: 5%;
  animation: textUp 1s ease-in;
  /* opacity: 0; */
}

h3 {
  animation: textUp 1s ease-in;
}

@keyframes textUp {
  from {
    opacity: 0;
    transform: translateY(20%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (max-width: 600px) {
  .leftB {
    visibility: hidden;
    display: none;
  }

  .rightB {
    visibility: hidden;
    display: none;
  }

  .mainImg {
    width: 300px;
    height: 300px;
  }
}

/* #Circle3 {
  height: 100px;
} */

/* #Circle3 {
  fill: red;
  stroke: green;
} */

/* #Circle3 {
  animation: grow 2s 1;
}

@keyframes grow {
  0% {
    opacity: 0;
    transform: scale(0);
  }

 

  100% {
    opacity: 1;
    transform: scale(1);
  }
} */
