.aboutMain {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* height: 100vh; */

  /* background-color: rgb(205, 203, 203); */
  padding-bottom: 3%;
}

.about {
  font-size: 50px;
  text-align: center;
}

.aboutText {
  text-align: center;
  transition: 0.5s ease-in;
}

.aboutText:hover {
  transform: scale(1.05);
}

@media screen and (max-width: 600px) {
  .aboutImg {
    width: 300px;
    height: 300px;
  }

  .aboutImgGrid {
    display: flex;
    align-items: center;
    padding-top: 50px;
  }
}
